import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import ITServicesArea from "@containers/it-service/layout-02";
import ContactArea from "@containers/contact/layout-02";

const Services = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT"
                title="India's Top IT Outsourcing Company: Projects Start At USD $1K - USD $1 Million"
                description="Over the course of the past 15 years, TEQTS has been offering high-value outsourcing projects with ticket sizes of up to 10 million US dollars. Outsourcing of SaaS, Artificial Intelligence Development, Outsourcing of Data Analysis, Outsourcing of Cybersecurity, Outsourcing of Web and App Development, and Many More."
                keywords="Software Development Outsourcing, App Development Outsourcing, Web Development Outsourcing, SaaS Product Development Outsourcing, AI Tools Development Outsourcing,  Data Analysis Outsourcing, Cyber Security Outsourcing, Digital Marketing Outsourcing and 
IOT Outsourcing"
            />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <ITServicesArea
                    data={{
                        ...content["service-section"],
                        items: data.allItService.nodes,
                    }}
                />
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query Services {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "appointment" }, pageType: { eq: "frontpage" }) {
            content {
                ...PageContent
            }
        }
        allItService(
            sort: { order: DESC, fields: id }
            filter: { is_featured: { eq: true } }
        ) {
            nodes {
                ...ItServiceOne
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
        allCaseStudy(filter: { is_featured: { eq: true } }, limit: 4) {
            nodes {
                id
                title
                slug
                category
                excerpt
                featured_image {
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: TRACED_SVG
                                formats: WEBP
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    }
`;

Services.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
            }),
        }),
        allItService: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allCaseStudy: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Services;
